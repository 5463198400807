import {getAuth, onAuthStateChanged} from '@firebase/auth';
import {initializeApp} from 'firebase/app';
import {sendPasswordResetEmail, signInWithEmailAndPassword, signOut} from 'firebase/auth';
import React, {createContext, useContext, useEffect, useState} from 'react';
import {useValidateUser} from '../features/dashboard/api';
import type {ReactElement} from 'react';
import type {LoginForm} from '../features/auth/types';
import type {User, UserCredential} from '@firebase/auth';
import type {OrgUser} from '../features/dashboard/types';

interface AuthContextType {
    user: User | null;
    orgUser?: OrgUser;
    isLoading: boolean;
    isError: boolean;
    login: ({email, password}: LoginForm) => Promise<UserCredential | undefined>;
    logout: () => void;
    sendPasswordReset: (email: string) => Promise<void>;
}

type Environment = 'production' | 'test' | 'development'
const environment = (process.env.REACT_APP_ENV) as Environment

// This needs to go to secrets
let firebaseConfig
switch (environment) {
    case 'production':
        firebaseConfig = {
            apiKey: "AIzaSyBDuezkAre91olhc4bgfyWRyiaSaKmQUew",
            authDomain: "mytimber-a59af.firebaseapp.com",
            projectId: "mytimber-a59af",
            storageBucket: "mytimber-a59af.appspot.com",
            messagingSenderId: "418662678642",
            appId: "1:418662678642:web:34525165c35c9a9ee242eb"
        }
        break;
    case 'test':
    case 'development':
        firebaseConfig = {
            apiKey: "AIzaSyBRdrjtp928bHuFmtxnCmoGPE8KvXs6-CA",
            authDomain: "mytimber-test.firebaseapp.com",
            projectId: "mytimber-test",
            storageBucket: "mytimber-test.appspot.com",
            messagingSenderId: "449655437831",
            appId: "1:449655437831:web:be61673aef73ca462dc8a2"
        }
        break; 
    default:
        throw new Error(`Unknown environment: ${environment}`);    
}

const app = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(app);

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const useAuthState = () => {
    const auth = useContext(AuthContext);
    return { ...auth, isAuthenticated: auth.user != null };
}

interface AuthenticationContextProps {
    children: ReactElement;
}

export const AuthenticationContext = ({children}: AuthenticationContextProps) => {
    const [user, setUser] = useState<User | null>(null)
    const {data: validatedUser, isLoading: isOrgUserLoading, isError: isOrgUserError} = useValidateUser()

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(firebaseAuth, async (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
            } else {
                await logout();
            }
        })

        return () => unsubscribe();
    }, [])

    const logInWithEmailAndPassword = async ({email, password}: LoginForm) => {
        try {
            return await signInWithEmailAndPassword(firebaseAuth, email, password);
        } catch (err: any) {
            return Promise.reject(err.code);
        }
    }

    const sendPasswordReset = async (email: string) => {
        try {
            return await sendPasswordResetEmail(firebaseAuth, email);
        } catch (err: any) {
             return Promise.reject(err.message);
        }
    }

    const logout = () => signOut(firebaseAuth).then(() => setUser(null))

    return (
        <AuthContext.Provider value={{
            user,
            orgUser: validatedUser,
            isLoading: isOrgUserLoading,
            isError: isOrgUserError,
            login: logInWithEmailAndPassword,
            logout,
            sendPasswordReset,
        }}>
            {children}
        </AuthContext.Provider>
    );
};
